import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './ModalDisplay.css';
import GmailGuide from './GmailGuide';
// import OutlookGuide from './OutlookGuide';
// import AppleMailGuide from './AppleMailGuide';

const ModalDisplay = (props) =>  {
    // const [activeTab, setActiveTab] = useState('1');

    // const toggleTab = (tab) => {
    //     if (activeTab !== tab) {
    //         setActiveTab(tab);
    //     }
    // };

    return (
        <Modal size='lg' isOpen={props.isOpen} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle} className="p-2 m-0 ml-2">
                <img width='24px' height='24px' alt="modal-logo" src={require('./../../../img/logo.png')} />
            </ModalHeader>
            <ModalBody>
                <GmailGuide/>
                {/* <div>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={`${activeTab === '1' ? 'active' : ''}`}
                                onClick={() => toggleTab('1')}
                            >
                                Gmail
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={`${activeTab === '2' ? 'active' : ''}`}
                                onClick={() => toggleTab('2')}
                            >
                                Outlook
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={`${activeTab === '3' ? 'active' : ''}`}
                                onClick={() => toggleTab('3')}
                            >
                                Apple Mail
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        
                    </TabPane>
                    <TabPane tabId="2">
                        <OutlookGuide/>
                    </TabPane>
                    <TabPane tabId="3">
                        <AppleMailGuide/>
                    </TabPane>
                </TabContent> */}
            </ModalBody>
        </Modal>
    );
}    

export default ModalDisplay;

