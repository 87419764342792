import React, { Component } from 'react'
import {BrowserRouter as Router,Switch,Route,} from "react-router-dom";
import AppCollapseNavBar from './component/leftContainer/AppCollapseNavBar'
import SignatureContainer from './component/rightContainer/SignatureContainer'
import Rating from './component/rating/Rating'
import Welcome from './component/Welcome';
import './App.css';
import { SOCIAL } from './constatns/social';
import Uninstall from './component/uninstall/Uninstall';

const CWSLogo = require('./img/cws-logo.svg')

class App extends Component {
  constructor(props){
    super(props)

    this.state={
      content:[
        {index:"1",id:'Full Name',type:"text",userInput:'Vlad Kabantsov',min:"20",max:"40"},
        {index:"2",id:'Role',type:"text",userInput:'Software Engineer',min:"20",max:"40"},
        {index:"1",id:'Email',type:"text",userInput:'kabantsovlad@gmail.com',min:"20",max:"40"},
        {index:"2",id:'Phone',type:"text",userInput:'(888) 555 0012',min:"0",max:"0"},
        {index:"1",id:'Address',type:"text",userInput:'Dauning str.',min:"0",max:"0"},
        {index:"2",id:'Website',type:"text",userInput:'email-toolkit.com',min:"0",max:"0"}
      ],
      social:[
        {index:"1",id: SOCIAL.FACEBOOK,userInput:'https://fb.com',type:"text",min:"20",max:"30",icon: 'https://email-toolkit.com/fb.png'},
        {index:"2",id: SOCIAL.INSTAGRAM,type:"text",userInput:'https://instagram.com',min:"20",max:"30",icon: 'https://email-toolkit.com/inst.png'},
        {index:"1",id: SOCIAL.TWITTER,type:"text",userInput:'https://x.com',min:"20",max:"30",icon: 'https://email-toolkit.com/x.png'},
        {index:"2",id: SOCIAL.LINKEDIN,type:"text",userInput:'https://linkedin.com',min:"20",max:"30",icon: 'https://email-toolkit.com/linkedin.png'},
        {index:"2",id: SOCIAL.YOUTUBE,type:"text",userInput:'https://youtube.com',min:"20",max:"30",icon: 'https://email-toolkit.com/youtube.png'}
      ],
      images:[
        {index:"1",id:"Logo",type:'file',userInput: 'https://email-toolkit.com/api/uploads/19cd5579-9014-45c3-baa6-3927e949dc85.png',min:"35",max:"60"},
        {index:"1",id:"Profile",type:'file',userInput: 'https://email-toolkit.com/api/uploads/072a2fe8-b16f-4301-9ee0-4ab6662282c8.png',min:"35",max:"60"}
      ],
      style:[
        {index:"1",id:"Background Color",userInput:'',type:'color',min:"20",max:"40"},
        {index:"1",id:"Text Color",userInput:'#333333',type:'color',min:"20",max:"40"},
        {index:"2",id:"Background Rounding",userInput:'4',type:'range',min:"0",max:"35"},
        {index:"2",id:"Row Spacing",userInput:'2',type:'range',min:"2",max:"5"},
        {index:"1",id:"Logo Size",userInput:'65',type:'range',min:"65",max:"80"},
        {index:"2",id:"Logo Image Rounding",userInput:'4',type:'range',min:"20",max:"40"},
        {index:"1",id:"Profile Image Size",userInput:'55',type:'range',min:"55",max:"61"},
        {index:"2",id:"Profile Image Rounding",userInput:'4',type:'range',min:"0",max:"40"},
        {index:"1",id:"Social Size",userInput:'20',type:'range',min:"20",max:"22"},
        {index:"2",id:"Social Rounding",userInput:'4',type:'range',min:"0",max:"30"}
      ],
      isOpenNavBar:false,
      isOpenModal:false,
      isChange:false,
      copied: false,
    }

  }

  handleImageChange=(event,inputId,listName)=>{
    if(event.target.files[0]) {
      this.setState({isChange:true})
      const image = event.target.files[0];
      const formData = new FormData();
      formData.append('file', image);

      fetch('https://email-toolkit.com/api/upload', {
        method: 'POST',
        body: formData,
      })
      .then(res => res.json())
      .then(({ url }) => {
        let updateList = this.state[listName];
        let updateObj = updateList.find(el => el.id === inputId);
        let index = updateList.findIndex(el => el.id === inputId);

        updateObj.userInput = url;
        updateList[index] = updateObj;
        
        this.setState({listName:updateList})
      })
      .catch(err => console.log(err))
    }

  }

  toggleNavBar=()=>{
    this.setState({isOpenNavBar:!this.state.isOpenNavBar});
  }

  toggleModal=()=>{
    this.setState({isOpenModal:!this.state.isOpenModal});
  }

  getList = (listName) => {
    return this.state[listName];
  }

  handleInputChange = (event,inputId,listName) =>{
    this.setState({isChange:true})
    event.preventDefault();
    let updateList=this.state[listName];
    let updateObj=updateList.find(el=>el.id===inputId)
    let index= updateList.findIndex(el=>el.id===inputId);
    updateObj.userInput=event.target.value;
    updateList[index]=updateObj;
    this.setState({listName:updateList})  
  }

  onOpenChromeExtension = () => {
    window.open('https://chromewebstore.google.com/detail/html-email-signature-gene/blajjgpdlhgkgikolkdpaicgicfhacgg', '_blank');
  }

  render() {
    return (
      <Router>
        <div className="App">
          {/* <AppNavBar toggle={this.toggleNavBar} isOpen={this.state.isOpenNavBar}></AppNavBar> */}
          <Switch>
              {/* <Route path="/guide">
                <UserGuide/>
              </Route> */}
              <Route path="/welcome">
                <Welcome/>
              </Route>
              <Route path="/uninstall">
                <Uninstall/>
              </Route>
              <Route path="/">
                <div className='sidebar'>
                  <div className='logo-sidebar'>
                    <div className='logo-container'>
                      <img width={50} height={50} src={require('./img/logo.png')} alt="logo" className="logo"/>
                    </div>
                    <span className='site-name'>Email signature</span>
                  </div>
                  <div className='btn-chrome-container' onClick={this.onOpenChromeExtension}>
                      <div className='btn-chrome'>
                        <img src={CWSLogo} alt='cws-logo'width={24} height={24}/> Chrome Extension
                      </div>
                  </div>
                </div>
                <div className="grid-container">
                  <div className="auto-column">
                    <AppCollapseNavBar
                      imgChange={this.handleImageChange}
                      change={this.handleInputChange}
                      getList={this.getList}
                    ></AppCollapseNavBar>
                  </div>
                  <div className="preview-column">
                    <SignatureContainer
                      isChange={this.state.isChange}
                      isOpen={this.state.isOpenModal}
                      toggle={this.toggleModal}
                      list={this.state}
                    />
                    <div className="rating-container">
                      <Rating />
                    </div>
                  </div>
                </div>
              </Route>
          </Switch>  
        </div>
      </Router>
    )
  }
}

export default App;

// https://ci3.googleusercontent.com/meips/ADKq_NY7-cIE-khQiBepiidsdwV8zQfetoFl3-Shn2BBd_IykKctBLWj49SRPoxKnK5YMgYkSU5h_18ROZC6cQdf9uIYES14-Q0DgkF-w_Zi7UpvYs4-=s0-d-e1-ft#
// https://cdn.mysigmail.com/signature/assets/icons/facebook.png