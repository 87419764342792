import React, { useState } from "react";
import './Rating.css';

const googleDocUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSfCXD2X0P0olshLkSouTHn8mzAEopNtu_TDBWeo--y58BBGAA/viewform';
const extensionUrl = 'https://chromewebstore.google.com/detail/html-email-signature-gene/blajjgpdlhgkgikolkdpaicgicfhacgg/reviews';

const RatingComponent = () => {
  const [hoveredStar, setHoveredStar] = useState(0); // Tracks the star being hovered
  const [selectedStar, setSelectedStar] = useState(0); // Tracks the star being selected

  const handleMouseEnter = (value) => {
    setHoveredStar(value);
  };

  const handleMouseLeave = () => {
    setHoveredStar(0);
  };

  const handleClick = (value) => {
    setSelectedStar(value);
  };

  const renderStar = (id, href) => {
    const isHighlighted = hoveredStar >= id || selectedStar >= id;

    return (
      <label
        key={id}
        onMouseEnter={() => handleMouseEnter(id)}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleClick(id)}
        style={{ cursor: "pointer", margin: "2px" }}
      >
        <a href={href}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            height="30"
            width="30"
            style={{
              fill: isHighlighted ? "#ffc711" : "#ff8400",
              transition: "fill 0.3s",
            }}
          >
            <path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z" />
          </svg>
        </a>
      </label>
    );
  };

  return (
    <div>
      <p style={{ textAlign: "center", fontFamily: "arial, sans-serif", fontSize: "16px" }}>
        Rate us:
      </p>
      <div className="full-stars">
        <div className="rating-group" style={{ display: "inline-flex" }}>
          {[1, 2, 3, 4, 5].map((value) =>
            renderStar(value, value < 4 ? googleDocUrl : extensionUrl)
          )}
        </div>
      </div>
    </div>
  );
};

export default RatingComponent;
