import React from "react";
import "./Uninstall.css";

const Uninstall = () => {

    return (
        <div className="delete-page-container">
            <h1 className="delete-page-title">We're sorry to see you go.</h1>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfGh1aNwyBqXDNhLKCH7oxyTg-Bt_tJJ-MRZ0HeO7vaBQJI0w/viewform?embedded=true" width="640" height="419" frameborder="0" marginheight="0" marginwidth="0" title="delete">Loading…</iframe>
        </div>
    )
}

export default Uninstall;