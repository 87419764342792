import React, { useState } from 'react';
import ModalDisplay from './UserGuide/ModalDisplay'
import SignatureDisplay from './SignatureDisplay'
import { Card, CardBody, Button} from 'reactstrap';
import Notification from '../shared/Notification';

const SignatureContainer = (props) => {
    const [isOpenGuide, setIsOpenGuide] = React.useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const lines = [
        'medium',
        'medium',
        'medium',
        'medium',
        'medium',
        'medium',
        'large',
        'large',
        'large',
    ]
    let copyBoxRef = React.createRef();

    const handleShowNotification = () => {
        setShowNotification(true);
    };

    const sendSignatureToExtension = (signatureHTML) => {
        window.postMessage(
            { type: "SEND_SIGNATURE", signature: signatureHTML },
            "*"
        );
    }

    const handleCopy = () => {
        handleShowNotification();
        const copyBoxElement = copyBoxRef.current;
        
        copyBoxElement.contentEditable = true;
        copyBoxElement.focus();
        document.execCommand('selectAll');
        document.execCommand('copy');
        copyBoxElement.contentEditable = false;
        getSelection().empty();
        setIsOpenGuide(true);
        sendSignatureToExtension(copyBoxElement.innerHTML);
    }

    const toggle = () => {
        setIsOpenGuide(prev => !prev)
    }

    return(
        <div className='email-container'>
            {showNotification && (
                <Notification
                    message="Your signature has been copied!"
                    onClose={() => setShowNotification(false)}
                />
            )}
            <Card  className="border rounded shadow">
                <div className='new-message'>
                    <span>New message</span>
                    <span>&#x2715;</span>
                </div>
                <CardBody className="rounded">
                    {lines.map((value, idx) => {
                        const classForLine = `line-item line-item-${value}`
                        return <div className={classForLine} key={idx}></div>
                    })}
                
                    <div className='signature-container'>
                        <div className="px-2" ref={copyBoxRef}>
                            <SignatureDisplay list={props.list} />
                        </div>
                        <div>
                            <Button className='btn-generate-signature'
                                style={{background:"#1B9C85"}}
                                onClick={handleCopy}
                            >Copy</Button>
                        </div>
                    </div>
                    
                </CardBody>
            </Card>
            <ModalDisplay
                list={props.list}
                isChange={setIsOpenGuide}
                toggle={toggle}
                isOpen={isOpenGuide}
            />
        </div>
    )
}

export default SignatureContainer;
