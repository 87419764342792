import React from 'react';
import './ModalDisplay.css';

const guideImg = require('../../../img/guide-page-1.png');

const GmailGuide = () => {
    return (
        <div className='step-container'>
            <h2 className='step-content'>
                <span aria-label='arrow' role="img" className="scaling-text">➡️</span> 
                <a href="https://mail.google.com/" target='_blank' rel="noopener noreferrer"> Open Gmail </a> 
                and click the icon to paste your signature into the email.
            </h2>
            <img src={guideImg} alt='paste your signature' className='img-container' />
        </div>
    )
}

export default GmailGuide;